#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
  flex: 1;
}

.jumbotron-text-shadow {
  font-size: 2.5rem;
  text-shadow: 1px 1px 1px #000000;
}

.primary-jumbotron-text-shadow {
  text-shadow: 1.5px 1.5px 3px #000000;
}

.page-link {
  cursor: pointer;
}
