.error-image-overlay {
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .error-image-container {
    display: inline-block;
    background-image: url("https://i.imgur.com/qIufhof.png");
    background-size: cover;
    background-position: center;
    width: 40vh;
    height: 40vh;
  }

  .error-image-text {
    font-size: 28px;
    color: #e97e26ea;
    text-align: center;
  }
}
